.ad-banner {
    background-color: #eee;
    color: #333;
    text-align: center;
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
}

.ad-top,
.ad-bottom {
    width: 100%;
}

.ad-left,
.ad-right {
    width: 150px;
    height: 100%;
}
